/* eslint-disable no-undef */
var main = document.getElementById("main");
var link = document.getElementById("test");
//console.log(link);
link.onclick = function(event){
    main.style.display="block";
    event.preventDefault();
}
window.onclick=function(event){
    if(event.target==main){
        main.style.display="none";
    }
    event.preventDefault();
}